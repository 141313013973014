/* eslint-disable */
import HttpService from '../HttpService';
export default class BenefitService {
  constructor() {
    this._httpService = new HttpService('/contract/benefit');
  }

  async FindAll() {
    return await this._httpService.get('/find-by-permission');
  }
 };
